<template lang="pug">
div.top-line#top-line
</template>

<script>
export default {

}
</script>

<style>

    .top-line{
        position: sticky;
        top:0; left:0; right:0;
        z-index: 1000;
        height: 0.4vw;
        width: 100%;
        background: linear-gradient(to left, #FF0000,#FFA500);
    }
    
    @media screen and (max-width: 1200px){
        #top-line {
            height: 0.6vw;
        }
    }

    @media screen and (max-width: 991px){
        #top-line {
            height: 0.8vw;
        }
    }

    @media screen and (max-width: 768px){
        #top-line {
            height: 1vw;
        }
    }

    @media screen and (max-width: 450px){
        #top-line {
            height: 1.2vw;
        }
    }
    
</style>