<template lang="pug">
<div class="swiper">
  <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
    <!-- Slides -->
        <div class="swiper-slide">
            h3 Ilustre Municipalidad de Paine, DAEM
            div.emblema
                img(src="../assets/municipalidad-paine.png" alt="Logo municipalidad de Paine")
        </div>
        <div class="swiper-slide">
            h3 Américas Modernas
            div.emblema
                img(src="../assets/america-moderna.jpg" alt="Emblema América Moderna")
        </div>
        <div class="swiper-slide">
            h3 My Garden School
            div.emblema
                img(src="../assets/my-garden.jpg" alt="Logo My Garden School")
        </div>
        <div class="swiper-slide">
            h3 Colegio San Guillermo
            div.emblema
                img(src="../assets/san-guillermo.jpg" alt="Emblema colegio San Guillermo")
        </div>
        <div class="swiper-slide">
            h3 Colegio Saint Peter
            div.emblema
                img(src="../assets/saint-peter.jpg" alt="Emblema Saint Peter College")
        </div>
        <div class="swiper-slide">
            h3 Escuela Ana Cruchaga
            div.emblema
                img(src="../assets/ana-cruchaga.png" alt="Emblema Escuela Ana Cruchaga")
        </div>
        <div class="swiper-slide">
            h3 Colegio Ecológico Paine
            div.emblema
                img(src="../assets/ecologico-paine.png" alt="Emblema Colegio ecológico Paine")
        </div>
        <div class="swiper-slide">
            h3 Colegio Larun Rayun
            div.emblema
                img(src="../assets/larun-rayun.jpg" alt="Emblema Centro educacional Larun Rayun")
        </div>
    </div>
    <!-- If we need pagination -->
    <div class="swiper-pagination"></div>

    <!-- If we need navigation buttons -->
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>

</div>  
</template>

<script>
export default {

}
</script>

<style>

    .swiper {
        box-sizing: border-box;
        width: 100%;
        height: 400px;
        }

    .swiper .swiper-wrapper .swiper-slide {
        padding: 0;
        border: 2px solid black;
        background: white;
        box-shadow: black;
        user-select: none;
        height: 100%;
        width: 100%;
    }    

    .swiper .swiper-wrapper .swiper-slide h3 {
        padding: 2rem;
        color: #FFDAB4;
        background: #FF7816;
        font-weight: bold;
        letter-spacing: 0.5px;
    }

    .swiper .swiper-wrapper .swiper-slide .emblema {
        
        height: 73.5%;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .swiper .swiper-wrapper .swiper-slide img {
        padding: 0;
        height: 60%;
        max-width: 300px;
    }

    #logo-l {
        max-width: 200px;
    }

    .swiper .swiper-button-next, .swiper .swiper-button-prev, .swiper .swiper-pagination{
        color: #FF7816;
    }

    @media screen and (max-width: 858px){
        
        .swiper {
            width: 85%;
            height: 350px;
        }
        
        .swiper .swiper-wrapper .swiper-slide h3 {
            font-size: 1.2rem;
            padding: 1.5rem;
        }
        
        .swiper .swiper-wrapper .swiper-slide img {
            padding: 1rem;
        }

    }


</style>